import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: ['text-summer-red-600', 'text-category-sm', 'md:text-category-lg', 'group-[.bg-black]/row:text-white'],
    description: ['text-preamble-sm', 'group-[.bg-black]/row:text-white'],
    headline: ['group-[.bg-black]/row:text-white'],
  },
  variants: {
    variant: {
      horizontal: {
        description: `sm:grid-cols-7:mt-3 sm:grid-cols-7:text-preamble-lg`,
        headline: `sm:grid-cols-4:text-headline-md sm:grid-cols-7:text-headline-xl`,
      },
      vertical: {
        base: `gap-3`,
        caption: `group-[.bg]/box:mx-4`,
        description: `grid-cols-5:text-preamble-sm grid-cols-7:text-preamble-sm grid-cols-7:text-body-sm sm:text-body-sm sm:grid-cols-5:text-preamble-lg sm:grid-cols-7:text-preamble-lg`,
        headline: `group-[.bg]/box:mx-4 group-[.bg]/box:mb-4 grid-cols-7:text-headline-lg sm:grid-cols-4:text-headline-md sm:grid-cols-5:text-headline-lg sm:grid-cols-7:text-headline-lg sm:grid-cols-8:text-headline-2xl`,
      },
      card: {
        base: `w-full auto-rows-min gap-0`,
        caption: `group-[.bg]/box:mx-4`,
        description: `grid-cols-5:text-preamble-sm grid-cols-7:text-preamble-sm sm:grid-cols-5:text-preamble-lg sm:grid-cols-7:text-preamble-lg`,
        group: `relative -top-6 ml-6 bg-linen-100 p-3 md:-top-9 md:left-9 md:ml-0 md:w-[80%] md:bg-white grid-cols-7:md:p-9`,
        headline: `text-headline-md grid-cols-4:md:before:hidden grid-cols-7:md:text-headline-xl grid-cols-7:md:before:block [&>span.underline]:relative [&>span.underline]:inline-block [&>span.underline]:no-underline [&>span.underline]:before:absolute [&>span.underline]:before:-bottom-1.5 [&>span.underline]:before:h-2.5 [&>span.underline]:before:w-[102%] [&>span.underline]:before:bg-[url('/image/article-card-blob.svg')] [&>span.underline]:before:bg-no-repeat`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
