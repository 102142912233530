import FeminaPlusLogo from '@/femina/public/image/femina-plus-logo.svg';
import { StandaloneComponent } from '@/types/component';
import {
  StandaloneArticleTeaserDefault,
  StandaloneArticleTeaserDefaultProps,
} from 'base/components/ArticleTeaser/Default';

export const FeminaStandaloneArticleTeaserDefault: StandaloneComponent<StandaloneArticleTeaserDefaultProps> = (
  props,
) => {
  return <StandaloneArticleTeaserDefault subscriptionLogo={<FeminaPlusLogo />} {...props} />;
};
